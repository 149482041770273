import React, { useState, useRef } from "react";
import "../styles/components/contactForm.css";
import { Squares } from "react-activity";
import "react-activity/dist/library.css";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    setLoading(true);
    handleSubmit()

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_KEY,
        process.env.REACT_APP_TEMPLATE_KEY,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormData({
            user_name: "",
            user_email: "",
            subject: "",
            message: "",
          });
          alert(
            "Email sent successfully. I'll reach out to you as soon as possible."
          );
        },
        (error) => {
          alert(
            "Email not sent due to technical errors. Please contact via valdompinga57@gmail.com"
          );
          console.log(error.text);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {

    if (
      formData.user_name.length < 3 ||
      formData.subject.length < 5 ||
      formData.message.length < 10
    ) {
      alert("Please fill in all fields correctly.");
      return;
    }
  };

  return (
    <form ref={form} className="contact-form" onSubmit={sendEmail}>
      {loading ? (
        <div className="loading-spinner-container">
          <div className="loading-spinner">
            <Squares size={64} className="loadingSquare"/>
            <div>Sending email...</div>
          </div>
        </div>
      ) : (
        <>
          <div className="form-row1 name-input">
            <label htmlFor="user_name">Name</label>
            <input
              type="text"
              id="user_name"
              name="user_name"
              value={formData.user_name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-row1 email-input">
            <label htmlFor="user_email">Email</label>
            <input
              type="email"
              id="user_email"
              name="user_email"
              value={formData.user_email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-row2">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-row3">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-row center-button">
            <button className="sendButton" type="submit">Send</button>
          </div>
        </>
      )}
    </form>
  );
};

export default ContactForm;
