import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/components/header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <img
          src="/images/ValdoVolizMpinga.jpg"
          alt="Valdo Mpinga"
          className="profile-image"
        />
        <div className="header-info">
          <h1 className="name">Valdo Mpinga</h1>
          <nav>
            <ul className="nav-list">
              <li className="nav-item">
                <NavLink to="/" exact className="nav-link">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/projects" className="nav-link">
                  Projects
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" className="nav-link">
                  Contact
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
