import React from "react";
import { FaDownload } from "react-icons/fa";
import "../styles/components/workExperienceCard.css";

const jobExperiences = [
  {
    companyLogo: "/images/institutions/ipvc.png",
    title: "Researcher",
    company: "Instituto Politécnico de Viana do Castelo",
    date: "September 2023 - March 2024 · 7 months",
    locality: "Viana do Castelo, Portugal",
    description:
      "Research Fellow in Project WP2 - Responsive Factory, specializing in traceability and monitoring solutions for textile value chains. Tasks include implementing IoT in textile production, developing traceability platforms, and integrating with global systems. Responsible for drafting scientific articles and presenting at conferences.",
  },
  {
    companyLogo: "/images/institutions/ipvc.png",
    title: "Researcher",
    company: "Instituto Politécnico de Viana do Castelo",
    date: "July 2022 - July 2023 · 1 year and 1 month",
    locality: "Viana do Castelo, Portugal",
    description:
      "Research Assistant in Project RnHealthTech_Technologies within the Health Tech Research Line of the TECH Program. Support the Coordinator in implementing Project Activity WP3. Tasks include studying forecasting methods, evaluating Machine Learning techniques for indoor air quality, and developing a web-based solution. Responsible for creating a pilot demonstrator, preparing a scientific article, and presenting findings. ",
  },
];

const WorkExperienceCard = () => {
  const downloadCV = () => {
    const fileUrl = "/files/CV_ValdoMpinga.pdf";
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = "CV_ValdoMping.pdf";
    a.click();
  };
  return (
    <div className="work-experience-container">
      <div className="work-experience">
        <h2 className="work-experience-title">Experience</h2>
        <div className="work-experience-content">
          {jobExperiences.map((experience, index) => (
            <div key={index} className="job-experience">
              <img src={experience.companyLogo} alt={experience.company} />
              <div>
                <h3>{experience.title}</h3>
                <p>{experience.company}</p>
                <p>{experience.date}</p>
                <p>{experience.locality}</p>
                <p>{experience.description}</p>
                <hr className="separator-line" />
              </div>
            </div>
          ))}
        </div>
        <button
          className="download-button"
          onClick={() => {
            downloadCV();
          }}
        >
          <FaDownload />
          Download CV
        </button>
      </div>
    </div>
  );
};

export default WorkExperienceCard;
