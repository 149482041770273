import * as React from "react";
import { createRoot } from "react-dom/client";
import
{
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import About from "./screens/About";
import Projects from "./screens/Projects";
import Contact from "./screens/Contact";
import ProjectDetails from "./screens/ProjectDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <About />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/projects/details",
    element: <ProjectDetails />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
