import React from "react";
import "../styles/components/infoCard.css";

const InfoCard = () => {
  return (
    <div className="container">
      <div className="info-card">
        <h2 className="info-card-title">Hello, I'm Valdo 🙋🏾‍♂️</h2>
        <p className="personal-description">
          I'm a computer engineering graduate originally from Tete, Mozambique,
          now based in Portugal, where I completed my bachelor's degree.
          Currently, I'm pursuing my master's degree and gearing up to enter the
          IT job market. My interests lie in backend development, and I'm
          dedicated to honing my skills in this area. Outside of academics, I
          enjoy working out, cooking whenever time allows, and gaming. With a
          focus on completing my degree and transitioning into the professional
          realm, I'm eager to apply my knowledge and skills to contribute to the
          tech industry.
        </p>
        <p className="contact-info">
          You can find me on{" "}
          <a
            href="https://github.com/ValdoMpinga"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
          <span>, </span>
          <a
            href="https://www.linkedin.com/in/valdompinga"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>{" "}
          and<span> </span>
          <a
            href="https://www.instagram.com/_valdex/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
