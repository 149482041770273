import React from "react";
import "../styles/components/projectList.css";
import { useNavigate } from "react-router-dom";
import { colors } from "../utils/contants";

const ProjectList = () => {
  const navigate = useNavigate();

  const projects = [
    {
      id: 1,
      title: "RnHealth Apps",
      description:
        "RnHealth applications were built to complement the research I conducted at IPVC (Instituto Politécnico Viana do Castelo) to forecast indoor radon gas concentration.",
      extendedDescription:
        "The RnHealth Apps consisted of four modules: " +
        "<ol>" +
        "<li><a href='https://github.com/ValdoMpinga/rnhealth-scripts' target='_blank' rel='noopener noreferrer'>RnHealth Scripts</a></li>" +
        "<li><a href='https://github.com/ValdoMpinga/rnhealthBackend' target='_blank' rel='noopener noreferrer'>RnHealth Backend</a></li>" +
        "<li><a href='https://github.com/ValdoMpinga/rnhealth-frontend' target='_blank' rel='noopener noreferrer'>RnHealth Frontend</a></li>" +
        "<li><a href='https://github.com/ValdoMpinga/persistent-rnhealth_api_consumer_app' target='_blank' rel='noopener noreferrer'>Persistent Rnhealth Api Consumer</a></li>" +
        "</ol>" +
        "<br />" +
        "RnHealth Scripts were responsible for training AI models using LSTM and Bidirectional LSTM Algorithm, forecasting indoor radon gas concentration from 1 to 6 hours ahead. We trained 168 models with different lag numbers over multiple days." +
        "<img src='/images/rnhealth/predictionChart.png' alt='Model training chart, showing test predictions vs actual values for radon gas concentration' />" +
        "<br />" +
        "In the RnHealth Backend app, developed using the Django framework, we integrated the best AI models with endpoints for radon gas forecasting." +
        "<br />" +
        "The RnHealth Frontend app, developed using React, was integrated with a real-time sensor. It requested environmental data (Temperature, Humidity, Radon, CO2, and Pressure) and, after processing by the backend, displayed the forecasts on a chart." +
        "<img src='/images/rnhealth/forecastChart.png' alt='Indoor radon concentration chart'/>",
      techLabels: ["Django", "React", "Machine Learning", "Typescript"],
      results:
        "To test the AI model in a real-world scenario, we set up a machine at IPVC. Inside a Docker container, a TypeScript code fetched sensor data every 7 hours, made forecasts, and stored them in a database. After running for a month, we successfully built a model providing insights into future radon concentration inside an indoor environment.",
      image: "/images/rnhealth/forecastChart.png",
      videoId: "znW-ksC39FM",
    },
    {
      id: 2,
      title: "Diabetes Mellitus",
      description:
        "Diabetes Mellitus is a web app made with the Django framework, also powered by AI to predict if a user has diabetes or not.",
      extendedDescription:
        "Diabetes is a chronic disease characterized by increased blood sugar levels. Our web app aims to predict if a user has diabetes, categorizing it into types 1, 2, or gestational diabetes. Without proper care, patients can experience hyperglycemia or hypoglycemia. Blood glucose stability is crucial, and our AI model predicts future states based on daily habits, from eating to physical exercise." +
        "<img src='/images/diabetes/diabetes.png' alt='Results of the one-month evaluation'/>",
      techLabels: ["Django", "Machine Learning"],
      results:
        "Despite training the AI model with limited data, the project was a success. It serves as a prototype that, with the right data and sponsorship, can be fully realized." +
        "<img src='/images/diabetes/1.png' alt='Beginning of the diabetes forecast form'/>" +
        "<img src='/images/diabetes/2.png' alt='End of the diabetes forecast form'/>",
      image: "/images/diabetes/diabetes.png",
      // githubUrl: [
      //   "https://github.com/ValdoMpinga/DiabetesMellitus",
      //   "https://github.com/ValdoMpinga/DiabetesMachineLearning",
      // ],
    },
    {
      id: 3,
      title: "Shuchu-chan",
      description:
        "Shūchū-chan is a Discord bot that helps you stay focused and organized with Pomodoro timers. It was developed by a group of friends who love productivity and anime.",
      extendedDescription:
        "Shūchū-chan is a Discord bot designed to streamline the Pomodoro technique, a popular time management method. By automating Pomodoro sessions, users can effortlessly initiate, pause, and track their focused work intervals, promoting productivity and minimizing disruptions. Developed using Discord.js, Shūchū-chan offers commands like start-doro, pause-doro, skip-doro, status-doro, reset-doro, and manual-doro for a seamless experience." +
        "<img src='/images/schuchu/schuchu.png' alt='The bot controlling pomodoros on a Discord server'/>",
      techLabels: ["JavaScript", "Discord JS"],
      results:
        "This app proves to be very useful for Pomodoro management, as it is used almost every day for Pomodoro tracking during group study sessions." +
        "<img src='/images/schuchu/1.png' alt='Some of the commands that can be executed with the bot'/>",
      image: "/images/schuchu/schuchu.png",
      githubUrl: ["https://github.com/ValdoMpinga/shuchu-chan"],
    },
    {
      id: 4,
      title: "Notebook Shelf",
      description:
        "Revolutionize note-taking with our powerful hybrid mobile app. Effortlessly digitize, organize, and access your notes anytime, anywhere. Simplify your life with our intuitive note-taking solution!",
      extendedDescription:
        "Notebook Shelf is a React Native mobile application developed to enhance note-taking experiences. It combines the physical aspect of writing in a notebook with the convenience and accessibility of digital storage. Users can create digital notebooks and manage their notes efficiently within the app. The app's goal was to complement a product called Infinite Book, a reusable notebook allowing users to write, erase, and rewrite infinitely. However, a challenge arose as there was no effective way to persist notes. To address this, I created this mobile app that leverages cameras to digitize pages. After digitization, users can create a virtual notebook with these pages and append them to an existing notebook if desired. The app stores notebooks in PDF format on Dropbox. To learn more about it and how to set it up, read this " +
        "<a href='https://www.linkedin.com/pulse/from-pen-pixel-my-journey-creating-virtual-valdo-mpinga/' target='_blank' rel='noopener noreferrer'>article</a> I wrote." +
        "<img src='/images/notebookShelf/1.png' alt='Some of Notebook Shelf's screens'/>",
      techLabels: ["React Native", "Node Js", "Dropbox Developer"],
      results:
        "The app was successfully built. To see it in action, check the video below:",
      image: "/images/notebookShelf/1.png",
      videoId: "U7SV4JtSuYM",
    },
    {
      id: 5,
      title: "Viana Wheels",
      description:
        "In collaboration with my colleagues for a school project, we collectively decided on an exciting theme – developing a Bike Tracking Application tailored for the vibrant city of Viana do Castelo.",
      extendedDescription:
        "As part of a collaborative school project, my colleagues and I embarked on the exciting challenge of developing the VianaBike Tracker App for the vibrant city of Viana do Castelo. Our goal was to create an intuitive Android Kotlin mobile application using advanced concepts like fragments, dependency injection, and MVVM architecture. Leveraging Google Maps API for real-time mapping, Supabase for cloud database integration, and incorporating features such as QR codes for quick reservations and a Node.js app for image storage, our project aimed to streamline bike tracking, reservations, and return processes. A demonstration video showcases the app's seamless functionality, illustrating how it enhances urban mobility while championing sustainability." +
        "<img src='/images/vianaWheels/1.png' alt='Some of Viana Wheel's screens'/>",
      techLabels: ["Android Kotlin", "Node Js", "Supabase"],
      results:
        "The app was successfully built. To see it in action, check the video below:",
      image: "/images/vianaWheels/1.png",
      videoId: "n0SuO1Mdb5c",
    },
  ];

  const navigateToAbout = (projectDetails) => {
    navigate("/projects/details", { state: { projectDetails } });
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

const techLabelColors = {
  Django: colors.DJANGO,
  React: colors.REACT,
  "Machine Learning": colors.MACHINE_LEARNING,
  Typescript: colors.TYPESCRIPT,
  "Node Js": colors.NODE_JS,
  "Dropbox Developer": colors.DROPBOX_DEVELOPER,
  JavaScript: colors.JAVASCRIPT,
  "Discord JS": colors.DISCORD_JS,
  "React Native": colors.REACT_NATIVE,
  "Android Kotlin": colors.ANDROID_KOTLIN,
  Supabase: colors.SUPABASE,
  Nginx: colors.NGINX, 
};


  return (
    <div className="project-list">
      {projects.map((project) => (
        <div key={project.id} className="project-card">
          <div className="project-image">
            <img
              src={project.image}
              alt={project.title}
              onClick={() => {
                navigateToAbout(projects[project.id - 1]);
              }}
            />
          </div>
          <div className="project-details">
            <h2 className="projectTitle">{project.title}</h2>
            <p className="projectDescription">{project.description}</p>
            <div className="tech-label">
              {project.techLabels.map((label, index) => (
                <span
                  key={index}
                  style={{
                    backgroundColor: techLabelColors[label] || getRandomColor(),
                    margin: "5px",
                    padding: "8px",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  {label}
                </span>
              ))}
            </div>
            <button
              className="detail-button"
              onClick={() => {
                navigateToAbout(projects[project.id - 1]);
              }}
            >
              Details
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectList;
