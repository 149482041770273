import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/global.css";
import "../styles/screens/projects.css";
import ProjectList from "../components/ProjectList";
import ScrollToTopButton from "../components/ScrollToTopButton";

function Projects() {
  return (
    <main>
      <Header />
      <div className="description">
        <div>
          <h1 className="projectsTitle">Projects</h1>
          <p className="intro">
            Here are a few projects I've undertaken during my academic studies,
            professional endeavors, and leisure time.
          </p>
        </div>
      </div>
      <ProjectList />
      <ScrollToTopButton />

      <Footer />
    </main>
  );
}

export default Projects;
