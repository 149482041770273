import React, { useEffect } from "react";
import { PiArrowFatUpFill } from "react-icons/pi";
import "../styles/components/scrollToTopButton.css";

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolledHeight = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const isScrolledToBottom = scrolledHeight >= documentHeight;

      document.querySelector(".scroll-to-top-button").style.display =
        isScrolledToBottom ? "block" : "none";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button className="scroll-to-top-button" onClick={scrollToTop}>
      <PiArrowFatUpFill className="arrowIcon" />
    </button>
  );
};

export default ScrollToTopButton;
