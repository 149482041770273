export const colors = {
    DJANGO: "#0C4B33", // Green
    REACT: "#61DAFB", // Blue
    MACHINE_LEARNING: "#F7931E", // Orange
    TYPESCRIPT: "#3178C6", // Dark Blue
    NODE_JS: "#8CC84B", // Light Green
    DROPBOX_DEVELOPER: "#0061FE", // Dropbox Blue
    JAVASCRIPT: "#F7DF1E", // Yellow
    DISCORD_JS: "#7289DA", // Discord Purple
    REACT_NATIVE: "#61DAFB", // Blue
    ANDROID_KOTLIN: "#A97BFF", // Purple
    SUPABASE: "#0044CC", // Supabase Blue
    NGINX: "#009639", // Nginx Green
};
