import React from "react";
import "../styles/components/educationCard.css";

const educationData = [
  {
    year: "September 2023 - July 2025",
    school: "Instituto Politécnico de Viana do Castelo",
    details: "Masters degree, Computer Engineering",
    logo: "/images/institutions/ipvc.png",
  },
  {
    year: "September 2019 - July 2023",
    school: "Instituto Politécnico de Viana do Castelo",
    details: "Bachelor degree, Computer Engineering",
    logo: "/images/institutions/ipvc.png",
  },
];

const EducationCard = () => {
  return (
    <div className="education-card-container">
      <div className="education-card">
        <div className="education-content">
          <div className="education-details">
            <h2 className="education-title">Education</h2>

            {educationData.map((education, index) => (
              <div key={index} className="education-experience">
                <img src={education.logo} alt="School Logo" />
                <div>
                  <p className="school">{education.school}</p>
                  <p>{education.details}</p>
                  <p>{education.year}</p>
                  {education.grade && <p>{education.grade}</p>}
                  {/* <hr className="separator-line" /> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


export default EducationCard;
