import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import YouTube from "react-youtube";
import ScrollToTopButton from "../components/ScrollToTopButton";
import "../styles/global.css";
import "../styles/screens/projectDetails.css";

function ProjectDetails() {
  const location = useLocation();
  const projectDetails = location.state.projectDetails;

  useEffect(() => {
    console.log(projectDetails);

    window.scrollTo(0, 0);
  }, [projectDetails]);

  const isYouTubeVideoAvailable = [1, 2,  5,6].includes(projectDetails.id);

  return (
    <main>
      <Header />
      <div className="project-detail-container">
        <div>
          <h1 className="projectTitle">{projectDetails.title}</h1>
          <p
            className="dangerous-html"
            dangerouslySetInnerHTML={{
              __html: projectDetails.extendedDescription,
            }}
          />
          <div className="results-container">
            <h2 className="results-title">Results</h2>
            <p
              className="dangerous-html"
              dangerouslySetInnerHTML={{
                __html: projectDetails.results,
              }}
            />
            {isYouTubeVideoAvailable && (
              <div className="youtube-video">
                <YouTube videoId={projectDetails.videoId} />
              </div>
            )}
          </div>
        </div>
      </div>
      <ScrollToTopButton />
      <Footer />
    </main>
  );
}

export default ProjectDetails;
