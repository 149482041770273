import React, { useEffect, useState } from "react";
import "../styles/components/footer.css";

const Footer = () => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.scrollHeight;

      setIsScrolledToBottom(scrolledToBottom);
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer className={`footer ${isScrolledToBottom ? "show" : ""}`}>
      <div className="center-section">
        <div className="left-section">@2024 Valdo Mpinga</div>
        <div className="right-section">
          Built with{" "}
          <a
            href="https://react.dev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span style={{ whiteSpace: "pre" }} className="reactSpan">
              {" "}
              React
            </span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
