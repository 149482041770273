import React from "react";
import Header from "../components/Header";
import "../styles/global.css";
import "../styles/screens/about.css";
import InfoCard from "../components/InfoCard";
import EducationCard from "../components/EducationCard";
import WorkExperienceCard from "../components/WorkExperienceCard";
import Publications from "../components/Publications";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollToTopButton";

function About() {
  return (
    <main>
      <Header />
      <InfoCard />
      <WorkExperienceCard />
      <EducationCard />
      <Publications />
      <div>
        <ScrollToTopButton />
      </div>
      <Footer />
    </main>
  );
}

export default About;
