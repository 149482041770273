import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/global.css";
import "../styles/screens/contact.css";
import ContactForm from "../components/ContactForm";
import ScrollToTopButton from "../components/ScrollToTopButton";

function Contact() {
  return (
    <main>
      <Header />
      <div className="contactContainer">
        <h1 className="contactTitle">Contact me</h1>
        <p className="warning">
          Please fill out the form below if you have any questions or would want
          to talk about a project. I'll respond as quickly as I can!
        </p>
      </div>
      <div>
        <ContactForm />
      </div>
      <ScrollToTopButton />
      <Footer />
    </main>
  );
}

export default Contact;
